import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as LinkNative } from 'react-router-dom';
import styled, { css } from 'styled-components';

import TextNative from '~/components/Text';

const Content = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  margin-bottom: -10px;
`;
const Text = styled(TextNative)`
  opacity: 0.5;
  letter-spacing: 0.235px;
  ${props =>
    props.active &&
    css`
      opacity: 1 !important;
      color: ${props.theme.colors.pri500}!important;
      font-weight: ${props.theme.fontWeight.bold} !important;
      letter-spacing: 0;
    `}

  &:hover {
    opacity: 1 !important;
    color: ${props => props.theme.colors.pri500} !important;
    font-weight: ${props => props.theme.fontWeight.bold} !important;
    letter-spacing: 0;
  }
`;

const WrapperMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Link = styled(props => <LinkNative {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: opacity 250ms ease;
  padding: 0 24px;
  cursor: pointer;
  letter-spacing: 1px;
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

const Menu = ({
  environments,
  containPaths,
  realm,
  containScopes,
  pathNameRuntime,
  getEnvriomentByFeatureFlag
}) => {
  const [, setPathName] = useState(`${realm}/build`);
  const environment = getEnvriomentByFeatureFlag(environments);
  return (
    <>
      <WrapperMenu>
        <Content>
          <Link
            disabled={containScopes(['CAPSULE:READ', 'PIPELINE:READ'])}
            onClick={() => setPathName(`${realm}/operation/build/pipelines`)}
            to={`/${realm}/operation/build/pipelines`}
            data-testid='build-link'
          >
            <Text
              active={containPaths([
                `${realm}/operation/build/pipelines`,
                `${realm}/operation/build/capsules`
              ])}
              as='span'
              size='lg'
            >
              Build
            </Text>
          </Link>
          <Link
            onClick={() => setPathName(`${realm}/operation/run`)}
            to={`/${realm}/operation/run/${pathNameRuntime()}`}
            disabled={!environment}
            data-testid='run-link'
          >
            <Text
              active={containPaths([`${realm}/operation/run`])}
              as='span'
              size='lg'
            >
              Run
            </Text>
          </Link>
          <Link
            to={`/${realm}/operation/monitor/${
              environment || environments[0]
            }/overview`}
            disabled={!environment}
            onClick={() =>
              setPathName(
                `${realm}/operation/monitor/${
                  environment || environments[0]
                }/overview`
              )
            }
            data-testid='monitor-link'
          >
            <Text
              active={containPaths([`${realm}/operation/monitor`])}
              as='span'
              size='lg'
            >
              Monitor
            </Text>
          </Link>
        </Content>
      </WrapperMenu>
    </>
  );
};

Menu.propTypes = {
  containPaths: PropTypes.func.isRequired,
  containScopes: PropTypes.func.isRequired,
  realm: PropTypes.string.isRequired,
  environments: PropTypes.arrayOf(PropTypes.string).isRequired,
  pathNameRuntime: PropTypes.func.isRequired,
  getEnvriomentByFeatureFlag: PropTypes.func.isRequired
};

export default Menu;
